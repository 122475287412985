jQuery( function ($) {

    let $window = $(window);
    let $body = $('body');

	$body.on('mousedown', function() {
		$body.addClass('using-mouse');
	});

	$body.on('keydown', function(event) {
		if (event.keyCode === 9) {
			$body.removeClass('using-mouse');
		}
	});


	$('.js-toggle-nav').on('click', function(e){
		e.preventDefault();
		$(this).parents('.section__nav').toggleClass('expanded');
		$(this).toggleClass('is-active');
	})

	$('.js-nav .nav-link').on('click', function(e){
		//e.preventDefault();
		$('.js-nav').removeClass('is-open');
	})
    

	$('[data-hide]').on('click', function() {
		$(this).parents('.' + $(this).attr('data-hide')).removeClass('show');
	});



	//Tabs
	$('.nav-tabs .nav-link').on('click', function(e) {
		e.preventDefault();

		var $tabLink = $(this);
		var $targetTab = $($tabLink.attr('href'));
		let $navLinkHolder = $tabLink.parents('.slick-slide');

		if ( $navLinkHolder ) {
			let $navLinkHolderSiblings =  $navLinkHolder.siblings();
			let $navLinkHolderSiblingsLink = $navLinkHolderSiblings.find('.nav-link')

			$($navLinkHolder).addClass('slick-current')

			$($navLinkHolderSiblings).removeClass('slick-current')
			$navLinkHolderSiblingsLink.removeClass('active')
		}

		$tabLink
			.attr( 'aria-selected', 'true' )
			.addClass( 'active' )
			.closest( '.nav-item' )
			.siblings()
			.find( '.nav-link' )
			.removeClass( 'active' )
			.attr( 'aria-selected', 'false' )

		$targetTab
			.addClass('show active')
			.siblings()
			.removeClass('show active')
		
	});
});