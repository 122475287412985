jQuery( function ( $ ) {

    $('#section-menu')
        .select2({
            minimumResultsForSearch: -1,
        })
        .on( 'change', function () {
            window.location.href = $(this).val();
        });

});