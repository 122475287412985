(function($) {
    $.expr.pseudos.external = function(obj){
        return !obj.href.match(/^mailto\:/)
               && !obj.href.match(/^tel\:/)
               && (obj.hostname != location.hostname)
               && !obj.href.match(/^javascript\:/)
               && !obj.href.match(/^$/)
               || obj.href.match(/\.pdf/)
    };

    $('a:external').attr('target', '_blank');

    //  jQuery( function() {
    //     $('a:external').attr('target', '_blank');
    //     // $('a:external').attr('data-test', '_blank');
    //  } );

})(jQuery);


