( function ($) {
    function sliderInit() {
        $('.js-slider-alt .slider__slides').slick({
            slidesToShow: 6,
            slidesToScroll:6,
            dots: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 374,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                    }
                }
            ]
        });

        $('.js-slider-base .slider__slides').slick({
            slidesToShow: 7,
            slidesToScroll: 7,
            dots: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 374,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots:false
                    }
                }
            ]
        });

        $('.js-slider .slider__slides').slick();

        $('.js-slider-dev').slick({
            arrows: true,
            slidesToShow: 10,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
            ]
        }).on('afterChange', function(currentSlide){
            let sliderContent = $('.slick-current').find('.nav-link').attr('href');
            let currentSlideLink = $('.slick-current').find('.nav-link');
            let otherSlides = $('.slick-current').siblings();
            let otherSlidesLink =  otherSlides.find('.nav-link');

            otherSlidesLink.removeClass('active')
            currentSlideLink.addClass('active')
            $(sliderContent).addClass('active').siblings().removeClass('active')
        })


        $('.js-slider-alt .slider__slides2').each( function () {
            const slides_to_show = ( $( '.slider__slide', this ).length < 5 ) ? 4 : 6;
            $(this).slick({
                slidesToShow: slides_to_show,
                slidesToScroll: slides_to_show,
                dots: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 374,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                        }
                    }
                ]
            });
        });

    }

    function setArrows() {
        $('.slider').each(function() {
            let sliderTheme = $(this).data('slider');
            $(this).find('.slick-prev').attr('aria-label', `Previous ${sliderTheme}`);
            $(this).find('.slick-next').attr('aria-label', `Next ${sliderTheme}`);
        });
    }


    $( function () {
        sliderInit();
        setArrows();
    });

})( jQuery );