( function ($) {

    $('.bio-card').on( 'click', '.js-popup', function ( e ) {
        e.preventDefault();
        let $card = $(this).closest( '.bio-card' );

        let img_tag = $( '.card__head img', $card )[0].outerHTML;

        let $name = $card.find( ".card-body .card-title" );
        let name = $name.length ? $name.text() : '';

        let $job_title = $card.find( ".card-body .job-title" );
        let job_title = $job_title.length ? $job_title.text() : '';

        let $bio = $card.find( ".card-body .bio" );
        let bio = $bio.length ? $bio.html() : '';


        let template = `
            <div class="popup">
                <div class="row">
                    <div class="col-lg-4">
                        ${ img_tag }
                    </div>
                    <div class="col-lg-8">
                        <div class="popup__content">
                            <h2 class="h4">${name}</h2>  
                            [job_title]	
                            <div class="popup__entry">${bio}</div>
                        </div>
                    </div>
                </div>
            </div>
        `;


        // add/remove job_title as required
        template = template.replace( '[job_title]', job_title ? `<h3 class="subtitle">${job_title}</h3>` : '' );


        $.magnificPopup.open({
            items: {
                src: template,
                type: 'inline'
            }
        });
    });

})( jQuery );