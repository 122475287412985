jQuery( function ( $ ) {

  const $open = $( '.open-search' );
  const $alert = $( '.alert' );
  const $close = $( '.btn-close', $alert );
  const $input = $( 'input[type=search]', $alert );

  const search_trap = focusTrap.createFocusTrap( $alert.get(0), {
    initialFocus: $input.get(0),
    clickOutsideDeactivates: true,
    onDeactivate () {
      $('.alert').removeClass('show');
      setTimeout( function () {
        $('.alert').removeClass('alert-visible');
      }, 400 );
    }
  });

	$open.on('click', function(e){
		$('.alert').addClass('show alert-visible');
    search_trap.activate();
  });

  $close.on( 'click', function ( e ) {
    search_trap.deactivate();
  });

});