jQuery(function ($) {
  const $toggle = $(".js-toggle");
  const $menu_trap = $(".menu-trap");
  const $menu = $(".primary-nav-wrap");

  const menu_trap = focusTrap.createFocusTrap($menu_trap.get(0), {
    initialFocus: $toggle.get(0),
    // returnFocus: $toggle.get(0),
    onActivate() {
      $toggle.addClass("is-active");
      $menu.addClass("is-open");
    },
    onDeactivate() {
      $toggle.removeClass("is-active");
      $menu.removeClass("is-open");
    },
  });

  $(".navigation--primary #menu-header > li.menu-item-has-children > a").after(
    "<span class='open-submenu' title='Open Sub Menu' next-page><i class='fas fa-caret-down'></i></span>"
  );

  $(".js-toggle").on("click", function (e) {
    menu_trap[$toggle.hasClass("is-active") ? "deactivate" : "activate"]();
  });
  $("li.menu-item").on("click", function () {
    if ($(this).children(".sub-menu").is(":visible")) {
      $(this).children(".sub-menu").hide();
      $(this).removeClass("active");
      $(this)
        .children(".open-submenu")
        .html("<i class='fas fa-caret-down'></i>");
    } else {
      $(this).children(".sub-menu").show();
      $(this).children(".open-submenu").html("<i class='fas fa-caret-up'></i>");
      $(this).addClass("active");
    }
  });
});
